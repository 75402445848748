import React from 'react'
import { Link } from 'gatsby'

import Img from '../components/responsiveCloudinary'
import styles from './caseStudyTile.module.scss'
import styled from 'styled-components'
import { media } from '../utils/style-utils'

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;

  ${media.medium`
    flex-basis: 50%;
    max-width: 50%;
  `} ${media.large`
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  `};
`

export default ({ caseStudy }) => {
  return (
    <Col key={caseStudy.slug}>
      <Link
        className={`${styles.link} ${styles.block}`}
        key={caseStudy.fields.slug}
        to={caseStudy.fields.slug}
      >
        <div className={styles.thumbnail}>
          <Img
            user="avenue"
            medium="50vw"
            large="440px"
            publicId={caseStudy.frontmatter.tileImage}
            alt=""
          />
        </div>
        <h3 className={styles.title}>{caseStudy.frontmatter.title}</h3>
        <p className={styles.description}>
          {caseStudy.frontmatter.description}
        </p>
      </Link>
    </Col>
  )
}
