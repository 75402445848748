import React from "react"

import InternalHeader from "../components/internalHeader"
import CaseStudyTile from "../components/caseStudyTile"
import CaseStudyTileV2 from "../components/caseStudyTileV2"
import GetInTouch from "../components/getInTouch"
import styled from "styled-components"
import { Row } from "../utils/style-utils"
import { graphql } from "gatsby"
import GlobalComponents from "./../components/globalcomponents"

const Blockquote = styled.blockquote`
  margin: 0 0 20px;
  color: ${(props) => props.theme.purple};
  font-weight: 500;
  font-size: 2.5rem;
  position: relative;
  padding: 0 15px;

  sup {
    font-size: 100%;
    color: ${(props) => props.theme["light-grey"]};
    top: 0px;

    &:first-child {
      left: 0;
      top: 12px;
      position: absolute;

      @media (min-width: 1024px) {
        top: 23px;
        left: 5px;
      }
    }
  }

  @media (min-width: 1024px) {
    font-size: 36px;
    padding: 0 30px;
    margin: 0 0 34px;
  }

  @media (min-width: 1600px) {
    font-size: 44px;
  }
`

const Block = styled.div`
  padding: 30px 20px 30px;
  margin: 0;

  @media (min-width: 1024px) {
    width: 66.6666%;
    padding-top: 96px;
    padding-bottom: 74px;
  }
`

const Author = styled.p`
  margin: 0;
  padding: 0 15px;

  strong {
    color: ${(props) => props.theme["dark-grey"]}
    font-weight: 500;
  }

  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0 30px;
  }
`

const IndexPage = ({ data }) => {
  return (
    <GlobalComponents>
      <InternalHeader
        title="Our work"
        description="“Working with Avenue was like going to a fine tailor and getting a beautiful custom suit made.”"
        flag="Portfolio"
        img="avenue-hero-image-case-studies_2x_etjv64"
        template="background"
      />

      <Row>
        <Block>
          <Blockquote>
            <sup>“</sup>
            Working with Avenue was like going to a fine tailor and getting a
            beautiful custom suit made.<sup>”</sup>
          </Blockquote>
          <Author>
            <strong>Franco Crea</strong> — Award-winning furniture designer
          </Author>
        </Block>
      </Row>

      <section>
        <Row>
          {data.allWpWork.nodes.map((cs) => {
            return <CaseStudyTileV2 key={cs.uri} caseStudy={cs} />
          })}
          {data.allMarkdownRemark.edges.map((cs) => {
            return (
              <CaseStudyTile key={cs.node.fields.slug} caseStudy={cs.node} />
            )
          })}
        </Row>
      </section>
      <GetInTouch
        left="In coffee we trust."
        center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
        right="Let's have a chat"
        action="/contact"
      />
    </GlobalComponents>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { type: { eq: "casestudy" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            tileImage
            description
          }
          fields {
            slug
          }
        }
      }
    }
    allWpWork(sort: { order: DESC, fields: date }) {
      nodes {
        title
        uri
        excerpt
        featuredImage {
          node {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
        date(formatString: "DD MMM YY")
      }
    }
  }
`
